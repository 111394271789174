import React from "react"
import { Badge } from "antd"
import { statusEnum } from "../constant"

export default [
  {
    title: "名称",
    dataIndex: "warehouseName",
    key: "warehouseName",
  },
  {
    title: "地址",
    dataIndex: "completeAddress",
    key: "completeAddress",
    render: (value, record) => {
      return record.districtStr + ` ${value}`
    },
  },
  {
    title: "认证状态",
    dataIndex: "auth",
    key: "auth",
    render: (value) => <Badge {...statusEnum[value]} />,
  },
  {
    title: "创建时间",
    dataIndex: "createdTime",
    key: "createdTime",
  },
]
