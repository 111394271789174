/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react"
import moIcon from "@/assets/mo.png"
import dangIcon from "@/assets/dang.png"
interface MapProps {
  idName: string
  lat: number // 纬度
  lon: number // 经度
  latLonList: any
  handMapLon?: any
  refreshMap?: any
}

declare let TMap: any

const TencentMap = ({ idName, lat, lon, latLonList, handMapLon, refreshMap }: MapProps) => {
  const mapRef = useRef<any>(null) // 地图实例
  const markerLayerRef = useRef<any>(null) // 标记图层实例
  const [markers, setMarkers] = useState<any>([]) // 存储标记

  // 初始化地图
  const initMap = () => {
    const center = new TMap.LatLng(lat ? lat : latLonList[0]?.lat, lon ? lon : latLonList[0]?.lon)
    const zoomLevel = 12

    // 初始化地图实例
    mapRef.current = new TMap.Map(idName, {
      center: center,
      zoom: zoomLevel,
      minZoom: 3,
      maxZoom: 18,
    })

    // 创建并初始化 MultiMarker
    markerLayerRef.current = new TMap.MultiMarker({
      map: mapRef.current,
      styles: {
        myStyle: new TMap.MarkerStyle({
          width: 15,
          height: 20,
          anchor: { x: 10, y: 20 },
          src: moIcon,
          offset: { x: 0, y: -30 },
          size: 12,
          color: "#1673FF",
          backgroundWidth: 120, // 标注点文本背景的宽度
          backgroundHeight: 27, // 标注点文本背景的高度
          backgroundColor: "#fff", // 文字背景框边线颜色
          backgroundBorderRadius: 5,
        }),
        clickStyle: new TMap.MarkerStyle({
          width: 15,
          height: 20,
          anchor: { x: 10, y: 20 },
          src: dangIcon,
          offset: { x: 0, y: -30 },
          size: 12,
          color: "#FF7D00",
          backgroundWidth: 83, // 标注点文本背景的宽度
          backgroundHeight: 27, // 标注点文本背景的高度
          backgroundColor: "#fff", // 文字背景框边线颜色
          backgroundBorderRadius: 5,
        }),
      },
      // geometries: [initialMarker],
    })
    // 在地图上添加其他地址的标记
    const markersArray = latLonList.map((item, index) => ({
      id: `marker_${index}`,
      styleId: "myStyle",
      position: new TMap.LatLng(item.lat, item.lon),
      content: item.content,
      properties: {
        title: `Marker at ${item.lat}, ${item.lon}`,
      },
    }))

    // 将初始化标记和所有地址标记添加到地图中
    markerLayerRef?.current?.setGeometries([...markersArray])
    // setMarkers([...markersArray])
    mapRef.current.on("click", (event: any) => {
      const clickedLatLng = event.latLng
      // 创建新的点击标记
      const newMarker = {
        id: `click_marker_${clickedLatLng.lat}_${clickedLatLng.lng}`, // 动态生成点击标记的 ID
        styleId: "clickStyle",
        content: "当前选择位置",
        position: clickedLatLng,
        properties: {
          title: `Marker at ${clickedLatLng.lat}, ${clickedLatLng.lng}`,
        },
      }
      // 更新标记状态（只保留初始化标记和新点击标记）
      const newMarkers = [...markersArray, newMarker]
      // 更新 MultiMarker 图层
      markerLayerRef.current.setGeometries(newMarkers)
      // 更新 markers 状态
      setMarkers(newMarkers)
      // 存储当前点击的标记
      handMapLon([clickedLatLng.lat, clickedLatLng.lng])
    })
  }

  // 更新地图和标记
  const updateMap = () => {
    const center = new TMap.LatLng(lat ? lat : latLonList[0]?.lat, lon ? lon : latLonList[0]?.lon)
    // 更新地图的中心位置
    mapRef.current.setCenter(center)
    // 更新标记位置
    const updatedMarker = latLonList.map((item, index) => ({
      id: `marker_${index}`,
      styleId: "myStyle",
      position: new TMap.LatLng(item.lat, item.lon),
      content: item.content,
      properties: {
        title: `Marker at ${item.lat}, ${item.lon}`,
      },
    }))
    // 更新标记图层
    markerLayerRef.current.setGeometries([markers, ...updatedMarker])
    setMarkers([updatedMarker])
  }

  useEffect(() => {
    // 如果地图没有初始化，则初始化地图
    if (!mapRef.current) {
      initMap()
    } else {
      // 地图已初始化，更新地图位置
      updateMap()
    }
  }, [lat, lon])

  return <div id={idName} style={{ width: "100%", height: "400px" }}></div>
}

export default TencentMap
