/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from "react"
import { Button, message, Space, Badge, Modal, Form, Select, Input } from "antd"
import { useNavigate } from "react-router-dom"
import { DetailPageContent } from "@src/components/DetailPageComponents/Content"
import { DetailPageHeader } from "@src/components/DetailPageComponents/Header"
import { DetailPageContainer } from "@src/components/DetailPageComponents/PageContainer"
import "./index.scss"
import SKTable from "@src/components/SkTable"
import schema from "./schema"
import columnsP from "./columns"
import { getWarehouseList, getTissueCityTreeList, getWarehouseDetails } from "../service"
import { handleData } from "../constant"
import Access from "@src/components/Access"
import { useAccess } from "@src/contents/useAccess"
import AddTicket from "./addTicket"

const Warehouse = () => {
  const tableRef = useRef<any>(null)
  const navigate = useNavigate()
  const access = useAccess()
  const [cloudOpen, setCloudOpen] = useState<any>({ open: false, type: "add", record: {} })
  const [cityTreeList, setCityTreeList] = useState<any>([]) //省市区
  const [dataList, setDataList] = useState<any>([]) //省市区

  const handBankTree = async () => {
    const [resultCityTreeList]: any = await Promise.allSettled([getTissueCityTreeList({})])
    if (resultCityTreeList.status == "fulfilled") {
      setCityTreeList(handleData(resultCityTreeList?.value?.data))
      const _option = handleData(resultCityTreeList?.value?.data)
      tableRef.current.setSearch("address", {
        props: {
          options: _option,
          changeOnSelect: true,
        },
      })
    }
  }
  const columns: any = [
    ...columnsP,
    {
      title: "操作",
      dataIndex: "operation",
      render: (text: any, record: any) => {
        return (
          <div>
            <Button
              type="link"
              onClick={async () => {
                const result = await getWarehouseDetails(record.id)
                if (result.code == 200) {
                  setCloudOpen({ open: true, type: "edit", record: result.data })
                }
              }}
            >
              详情
            </Button>
          </div>
        )
      },
    },
  ]
  const handInit = async () => {
    const res: any = await getWarehouseList({
      current: 1,
      size: 999,
    })
    if (res.code == 200) {
      const _dataList = res?.data?.records?.map((ie) => {
        return {
          lat: ie.latitude,
          lon: ie.longitude,
          content: ie.warehouseName,
        }
      })
      setDataList(_dataList)
    }
  }
  const handleRequestData = async (params: any) => {
    const { current, pageSize, keyword, auth, address, ...rest } = params
    const res: any = await getWarehouseList({
      current,
      size: pageSize,
      auth: auth,
      keyword: keyword,
      address: address?.join("/"),
      ...rest,
    })
    return {
      data: res?.data?.records,
      total: res?.data?.total,
    }
  }
  const upDataTable = () => {
    tableRef.current.upData()
    handInit()
  }
  useEffect(() => {
    handInit()
    handBankTree()
  }, [])
  return (
    <DetailPageContainer>
      <DetailPageHeader
        title={
          <Space>
            <span>云仓管理</span>
          </Space>
        }
        breadCrumb={[
          { label: "物流管理", path: "" },
          { label: "云仓管理", path: "" },
        ]}
        rightContent={""}
      ></DetailPageHeader>
      <DetailPageContent>
        <SKTable
          title={"云仓管理列表"}
          ref={tableRef}
          toolbarRender={
            <>
              <Button type="primary" onClick={() => setCloudOpen({ open: true, type: "add" })}>
                新增云仓
              </Button>
            </>
          }
          pagination={{
            pageSize: 10,
            showQuickJumper: true,
            showTotal(total, range) {
              return `总共${total}条`
            },
          }}
          request={handleRequestData}
          pageChangeWithRequest={true}
          columns={columns}
          searchFlag={true}
          search={{
            mode: "simple",
            schema: schema,
          }}
        ></SKTable>
      </DetailPageContent>
      <AddTicket
        setCloudOpen={setCloudOpen}
        cloudOpen={cloudOpen}
        upDataTable={upDataTable}
        dataList={dataList}
        cityTreeList={cityTreeList} //省市区List
      />
    </DetailPageContainer>
  )
}
export default Warehouse
